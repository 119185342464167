import styles from './CluesPanel.module.css';

const CluesPanel = (props) => {

    let componentAcrossList = [];
    let componentDownList = [];

    props.clueAcrossList.forEach (clue => {
        componentAcrossList.push(<p>{clue}</p>);
    })
    
    props.clueDownList.forEach (clue => {
        componentDownList.push(<p>{clue}</p>);
    })

    return (
        <div className = {styles.wholeBody}>
            <div className = {styles.blocks}>
                <h1 className = {styles.titles}>Across</h1>
                {componentAcrossList}
            </div>
            <div className = {styles.blocks}>
                <h1 className = {styles.titles}>Down</h1>
                {componentDownList}
            </div>
        </div>
    )
}

export default CluesPanel;