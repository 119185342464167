import styles from './Cell.module.css';

const Cell = (props) => {
    return (
        <div className = {styles.outerBlock} id = {'o' + props.uniqueId}>
            <div className = {styles.cellBlock} id = {'i' + props.uniqueId}>
                <div className = {styles.cellNumber} id = {'no' + props.uniqueId}>{props.cellNumber}</div>
                <input className = {styles.cellText} type = 'text' id = {props.uniqueId} onChange={props.changeHandler} onClick = {props.clickHandler} onBlur = {props.clickAwayHandler} onFocus = {props.focusHandler} onBeforeInput = {props.beforeInputHandler}/>
            </div>
        </div>
    )
}

export default Cell;
