import { useState } from 'react';
import styles from './Registration.module.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';

const Registration = () => {
    let [result, setResult] = useState('');
    let [registered, setRegistered] = useState(false);

    function submitHandler () {
        
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;
        const cpassword = document.getElementById('cpassword').value;

        if(username != '' && password != '' && cpassword != '') {
            if(password != cpassword) {
                setResult('Passwords do not match');
            }
            else {
                $.ajax ({
                    type : 'POST',
                    url : 'userRegistration.php',
                    data : {
                        'type' : 'signup',
                        'username' : username,
                        'password' : password,
                    },
                    success(data) {
                        console.log(data);
                        if(data == 'true') {
                            setRegistered(true);
                        }
                        else {
                            setResult('Username not available');
                        }
                    }
                })
            }
        }
    }

    if(registered) {
        return (
            <div className = {styles.wholebody}>
                <div className = {styles.box}>
                    <p3 className = {styles.heading} style = {{textAlign : 'center'}}>Registered successfully</p3>
                </div>
            </div>
        )
    }

    return (
        <div className = {styles.wholebody}>
            <div className = {styles.box}>
                <p className = {styles.heading} >Username</p>
                <input type='text' className = {styles.input} id = 'username'/>
                <p className = {styles.heading}>Password</p>
                <input type='password' className = {styles.input} id = 'password'/>
                <p className = {styles.heading}>Confirm Password</p>
                <input type='password' className = {styles.input} id = 'cpassword'/>
                <button className = {styles.button} onClick = {submitHandler}>Sign Up</button>
                <p className = {styles.heading} style = {{textAlign : 'center'}}>{result}</p>
                <p className = {styles.text}>Already have an account? signin <Link to = '/nav/signIn' className = {styles.link}>here</Link></p>
            </div>
        </div>
    )
}

export default Registration;