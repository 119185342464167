import { useState } from 'react';
import LetterBoxCell from './LetterBoxCell.js';
import styles from './LetterBoxGrid.module.css';
import Keyboard from './Keyboard.js';
import $ from 'jquery';

const LetterBoxGrid = (props) => {
    let [numberOfGuesses, setNumberOfGuesses] = useState(0);
    let [counter, setCounter] = useState(0);
    let [gameComplete, setGameComplete] = useState(false);

    let arr = [];
    for(let i = 0; i < props.word.length + 1; i++)
    {
        let tempArr = [];
        for(let j = 0; j < props.word.length; j++)
        {
            tempArr.push(<td> <LetterBoxCell uniqueId = {i.toString() + j.toString()} isDisabled /> </td>);
        }
        arr.push(<tr>{tempArr}</tr>);
    }

    const finalTable = [];
    finalTable.push(<table>{arr}</table>);

    function setLevelComplete () {
        $.ajax({
            type : 'POST',
            url : 'setLevelComplete.php',
            data : {
                'username' : localStorage.getItem('token'),
                'levelname' : 'word' + props.levelNo,
            }
        })
    }

    function evaluate() {
        if(counter < props.word.length)
            return;
        setCounter(0);
        let correctGuess = true;
        const correctWord = props.word.toUpperCase();
        for(let i = 0; i < props.word.length; i++)
        {
            const currentCell = document.getElementById(numberOfGuesses.toString() + i.toString());
            const userInput = currentCell.value.toUpperCase();
            
            if(userInput != correctWord[i])
            {
                if(correctWord.includes(userInput))
                {
                    currentCell.style.backgroundColor = "#d79922";
                    currentCell.parentElement.style.backgroundColor = "#d79922";
                }
                else
                {
                    currentCell.style.backgroundColor = "#747474";
                    currentCell.parentElement.style.backgroundColor = "#747474";
                }
                correctGuess = false;
            }
            else
            {
                currentCell.style.backgroundColor = "#14a76c";
                currentCell.parentElement.style.backgroundColor = "#14a76c";
            }
        }

        if(numberOfGuesses == props.word.length + 1)
        {
            return;
        }

        setNumberOfGuesses(++numberOfGuesses);

        if(correctGuess)
        {
            document.getElementById('won').style.display = 'flex';
            setGameComplete(true);
            if(localStorage.getItem('token') != null) {
                setLevelComplete();
                console.log(props.levelNo);
            }
        }

        if(!correctGuess && numberOfGuesses == props.word.length + 1)
        {
            document.getElementById('lost').style.display = 'flex';
            setGameComplete(true);
        }
    }

    function onClickHandler(event) {
        if(gameComplete)
            return;
        if (event.target.innerText == 'ENTER')
            evaluate()
        else if (event.target.innerText == 'BACKSPACE')
            backSpaceHandlerEvent()
        else if (counter != props.word.length) {
        const currentCell = document.getElementById(numberOfGuesses.toString() + counter.toString());
        currentCell.value = event.target.innerText;
        counter++;} 
    }

    function backSpaceHandlerEvent() {
        if(gameComplete)
            return;
        if(numberOfGuesses == props.word.length + 1)
        {
            return;
        }
        if (counter == 0)
            return;
        const currentCell = document.getElementById(numberOfGuesses.toString() + (counter-1).toString());
        currentCell.value = '';
        counter--;
    }

    return (
        <div className = {styles.wholeBody}>
            <div className = {styles.outerBox}>
                {finalTable}
            </div>
            <Keyboard onClickHandler = {onClickHandler} />
        </div>
    )
}

export default LetterBoxGrid;