import CellGrid from "../CrossWord/CellGrid";
import styles from "./CrossWord.module.css";
import CluesPanel from "../CrossWord/CluesPanel";
import $ from "jquery";
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";

const CrossWord = () => {
    let [crosswordArray, setCrosswordArray] = useState('');
    let [across, setAcross] = useState('');
    let [down, setDown] = useState('');
    let [isLoading, setIsLoading] = useState(true);

    const index = useLocation()['pathname'].lastIndexOf('/');
    const id = useLocation()['pathname'].substring(index + 1);

    $.ajax({ 
        type: "POST", 
        url: "crossword.php",
        data: { 'id' : id.toString() },
        success(data) {
            if (isLoading) {
                setCrosswordArray(JSON.parse(data)[0]);
                setAcross(JSON.parse(data)[1]);
                setDown(JSON.parse(data)[2]);
                setIsLoading(false);
            }
        }, 
    });

    if(isLoading) {
        return (
            <div>
                Loading-----------------
            </div>
        )
    }

    return (
        <div className = {styles.wholePage} >
            <CellGrid crossWord = {crosswordArray} levelNo = {id}/>
            <CluesPanel clueAcrossList = {across} clueDownList = {down} />
            <div className = {styles.result} id = 'won'>
                <center>Congratulations! You have conquered this tough crossword</center>
                <Link to = '/nav/crosswordLevels' className = {styles.h3}>All Levels</Link>
            </div>
        </div>
        
    )
}

export default CrossWord;