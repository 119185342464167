import $ from 'jquery';
import styles from './AdminDashboard.module.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const AdminDashboard = () => {
    let [adminLogin, setAdminLogin] = useState(false);
    let [result, setResult] = useState('');

    function loginHandler () {
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;

        if(username != '' && password != '') {
            $.ajax({
                type : 'POST',
                url : 'adminValidation.php',
                data : { 'username' : username, 'password' : password },
                success(data) {
                    if(data == 'true') {
                        setAdminLogin(true);
                        sessionStorage.setItem('admin', 'valid');
                    }
                    else {
                        setResult('Invalid username or password');
                    }
                }
            })
        }
    }

    if (adminLogin) {
        return (
            <div className = {styles.fullBody}>
                <Link to = '/nav/crosswordEntry' className = {styles.banner}>
                    Technical Crossword
                </Link>
                <Link to = '/nav/guessWordEntry' className = {styles.banner}>
                    Guess The Word
                </Link>
            </div>
        )
    }

    return (
        <div className = {styles.login}>
            <h2>Username</h2>
            <input type = 'text' id = 'username' />
            <h2>Password</h2>
            <input type = 'password' id = 'password' />
            <button onClick = {loginHandler} style = {{marginTop: '20px'}}>Login</button>
            <h2>{result}</h2>
        </div>
    )
}

export default AdminDashboard;