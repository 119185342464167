import { useEffect, useState } from "react";
import styles from './CrosswordEntry.module.css';
import $ from 'jquery';

const CrosswordEntry = () => {
    let [rows, setRows] = useState(1);
    let [cols, setCols] = useState(1);   
    let [arr, setArr] = useState([]);
    let [result, setResult] = useState('');

    function clickHandler () {
        setRows(parseInt(document.getElementById('rows').value));
        setCols(parseInt(document.getElementById('cols').value));
    }

    function changeHandler () {
        let arr1 =[];
        let temp1 = [];
        for (let i = 0; i < rows; i++) {
            let temp = [];
            for (let j = 0; j < cols; j++) {
                temp.push(<td><input className = {styles.input} type = 'text' id = {i.toString() + '.' + j .toString()} maxLength={1}/></td>);
            }
            temp1.push(<tr>{temp}</tr>);
        }
        arr1.push(<table>{temp1}</table>);
        setArr(arr1);
    }

    useEffect (() => {
        changeHandler();
    }, [rows, cols])

    function submitHandler () {
        let characters = [];
        let ids = [];

        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < cols; j++) {
                const id = i.toString() + '.' + j.toString();
                ids.push(id);
                if(document.getElementById(id).value == '') {
                    characters.push('-1');
                }
                else {
                    characters.push(document.getElementById(id).value.toUpperCase());
                }
            }
        }
        
        const strAcross = document.getElementById('across').value;
        const strDown  = document.getElementById('down').value;
        
        const acrossArr = strAcross.split('\n');
        const downArr = strDown.split('\n');
        
        $.ajax ({
            type : 'POST',
            url : 'crosswordEntry.php',
            data : {
                'characters' : characters,
                'ids' : ids,
                'acrossArr' : acrossArr,
                'downArr' : downArr,
            },
            success(data) {
                setResult(data);
            }
        });
        
    }

    if (sessionStorage.getItem('admin') != 'valid') {
        return (
            <div>
                Not Authorized!
            </div>
        ) 
    }

    return (
        <div className = {styles.wholeBody} >
            <div className = {styles.crossword} >
                *Leave empty for black boxes
                <input type = 'text' placeholder="number of rows" id = 'rows' />
                <input type = 'text' placeholder="number of columns" id = 'cols'/>
                <button onClick={clickHandler}>Change</button>
                {arr}
                <button onClick={submitHandler}>Submit</button>
                {result}
            </div>
            <div classname = {styles.clues}>
                <h3>Across Clues</h3>
                <textarea id = 'across' rows = '15' cols = '100'/>
                <h3>Down Clues</h3>
                <textarea id = 'down' rows = '15' cols = '100'/>
            </div>
        </div>
        
    )
}

export default CrosswordEntry;