import { Link } from "react-router-dom";
import styles from './Menu.module.css';

const Menu = () => {
    return (
        <div className = {styles.fullBody}>
            <Link to = '/nav/crosswordLevels' className = {styles.banner}>
                Technical Crossword
            </Link>
            <Link to = '/nav/guessTheWordLevels' className = {styles.banner}>
                Guess The Word
            </Link>
        </div>
    )
}

export default Menu;