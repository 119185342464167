import styles from './Keyboard.module.css';

const Keyboard = (props) => {
    let arrayOfKeys = [ 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'BACKSPACE', '-1', 
                        '', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'ENTER', '-1',
                        '', '', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '', ''];

    let keyboardTable = [];

    let temp = [];
    for (let i = 0; i < arrayOfKeys.length; i++) {
        if (arrayOfKeys[i] != '-1' && arrayOfKeys[i] != '') {
            temp.push(<td className = {styles.keys} onClick = {props.onClickHandler}>{arrayOfKeys[i]}</td>)
        }
        else if (arrayOfKeys[i] == '') {
            temp.push(<td>{arrayOfKeys[i]}</td>)
        }
        else {
            keyboardTable.push(<tr>{temp}</tr>);
            temp = [];
        }
    }
    keyboardTable.push(<tr>{temp}</tr>);

    return (
        <table className = {styles.table}>
            {keyboardTable}
        </table>
    )
}

export default Keyboard;