import styles from './NavBar.module.css';
import { Outlet, Link } from "react-router-dom";
import UserProfile from './UserProfile.png';
import Menu from './menu.png';
import { useState } from 'react';

const NavBar = (props) => {
    let logged = '';
    let [menu, setMenu] = useState(false);

    if(localStorage.getItem('token') == null)
    {
        logged = false;
    }

    if(localStorage.getItem('token') != null)
    {
        logged = true;
    }

    function logout () {
        localStorage.removeItem('token');
        logged = false;
        window.location.reload();
    }

    function menuClickHandler () {
        document.getElementById('menu').style.display = 'none';
    }

    function menubarClickHandler () {
        if(menu) {
            document.getElementById('menu').style.display = 'none';
            setMenu(false);
        }
        else {
            document.getElementById('menu').style.display = 'flex';
            setMenu(true);
        }
    }

    return (
        <div>
            <div className = {styles.background}>
                <Link to = '/' className = {styles.title}>
                    Tech Term Guesser
                </Link>
                <Link to = '/nav/menu' className = {styles.more}>
                    Games
                </Link>
                <Link to = '/nav/dashboard' className = {styles.more} style = {{textDecoration: 'none'}}>Admin</Link>
                <Link to = '/nav/credits' className = {styles.more} style = {{textDecoration: 'none'}}>Credits</Link>
                {logged ? 
                    <div className = {styles.profile}>
                        <img src = {UserProfile} className = {styles.profile} />
                        <div className = {styles.profileContent}>
                            <Link className = {styles.options} onClick = {logout}>Logout</Link>
                        </div>
                    </div>
                    :
                    <div style = {{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                        <Link to = '/nav/signIn' className = {styles.signIn}>Sign In</Link>
                    </div>
                }
                <div className = {styles.menubar} id = "menubar" onClick = {menubarClickHandler}>
                    <img src = {Menu} className = {styles.menuIcon} />
                    <div className = {styles.menu} id = "menu">
                        <Link to = '/nav/menu' className = {styles.menuOptions} onClick={menuClickHandler}>
                        Games
                        </Link>
                        <Link to = '/nav/credits' className = {styles.menuOptions} onClick={menuClickHandler}>Credits</Link>
                    </div>
                </div>
            </div>
            
            <Outlet />
        </div>
        )
    
}

export default NavBar;