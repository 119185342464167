import $ from 'jquery';
import styles from './GuessWordEntry.module.css';
import { useState } from 'react';

const GuessWordEntry = () => {
    let [result, setResult] = useState('');

    function clickHandler (event) {
        if(document.getElementById('inputWord').value != '' && document.getElementById('hint').value != '')
        {
            const obj = {
                'word' : document.getElementById('inputWord').value,
                'hint' : document.getElementById('hint').value
            }
            $.ajax ({
                type : 'POST',
                url : "wordle.php",
                data : obj,
                success(data) {
                    setResult(data);
                },
            });
        }
    }

    if (sessionStorage.getItem('admin') != 'valid') {
        return (
            <div>
                Not Authorized!
            </div>
        )  
    }

    return (
        <div className = {styles.wholeBody} >
            <h1>GuessTheWord word input system</h1>
            <input className = {styles.input} type = 'text' id = 'inputWord' placeholder = 'Word'/>
            <input className = {styles.input} type = 'text' id = 'hint' placeholder = 'Hint'/>
            <button className = {styles.button} onClick = {clickHandler} >Add Word</button>
            <h2>{result}</h2>
        </div>
    )
}

export default GuessWordEntry;