import styles from './LevelThumbnail.module.css';
import { Link } from 'react-router-dom'; 
import $ from 'jquery';
import checked from './checked.png';
import { useState } from 'react';

const LevelThumbnail = (props) => {
    let [complete, setComplete] = useState(false);

    if(localStorage.getItem('token') != null) {
        $.ajax({
            type : 'POST',
            url : 'levels.php',
            data : {
                'levelname' : props.puzzle + props.levelNumber,
                'username' : localStorage.getItem('token'),
            },
            success(data) {
                if(data == 'true') {
                    setComplete(true);
                }
            }
        })
    }

    return (
        <Link to = {props.link + props.id} className = {styles.link}>
            <div className = {styles.outerBox} onClick = {props.clickHandler} id = {props.id}>
                {complete ? <img src = {checked} className = {styles.img} /> 
                : <img src = {props.thumbnailImg} className = {styles.img} />}
                <p3  className = {styles.link}>Level {props.levelNumber}</p3>
            </div>
        </Link>
        
    )
}

export default LevelThumbnail;