import styles from './Home.module.css';
import { Link } from 'react-router-dom';
import HomeBackground1 from './HomeBackground2.jpeg';

const Home = () => {

    return (
        <div className = {styles.wholeBody} >
            <div className = {styles.text} >
                <p className = {styles.title} >Tech Term Guesser</p>
                <p>Technical Word Games</p>
                <p className = {styles.paragraph}>Are you a tech enthusiast? Do you feel the need to exercise your brain, but get some break from coding? Then this word-puzzle-game is made just for you.</p>
                <Link to = "/nav/menu" className = {styles.slogan}>Let's Go!</Link>
            </div>
            <img src = {HomeBackground1} className = {styles.image} />
        </div>
    )
    /*return (
        <div className = {styles.wholeBody}>
            <img src = {HomeBackground1} className = {styles.img}/>
            <Link to = "/nav/menu" className = {styles.slogan}>Play Now!</Link>
            <h1 className = {styles.title} >What is technical crossword?</h1>
            <p className = {styles.paragraph}>Are you a tech enthusiast? Do you feel the need to exercise your brain, but get some break from coding? Then this word-puzzle-game is made just for you.</p>
            <p className = {styles.paragraph}><Link to = '/nav/crosswordLevels' className = {styles.white}>Technical Crossword</Link>, as well as <Link to = '/nav/guessTheWorldLevels' className = {styles.white}>Guess The Word</Link>, both brain teasers challenge your thinking skills and knowledge alike. Technical crosswords are like traditional crosswords but there is a catch, all the words are 'Technical Terms'. You will be given an empty canvas and some clues to solve this crossword. But think carefully as the clues might not be so direct. Guess all the words correctly to successfully complete the crossword. On the other hand, guess the word makes you guess a single word but gives you less room for error, as there are only a handful attempts to get it right. Use 'Hint' to gain some knowledge about the intended word. Hints are more subtle than crossword clues and will not provide you with direct answer. But worry not, after each guess letters will change colors based on accuracy of your guess and help you get closer to the answer.</p>
            <p className = {styles.paragraph}>So are you ready to delve into the world where traditional games meet technology? Join now! Play Now!</p>
        </div>
    )*/
}

export default Home;