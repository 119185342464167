import styles from './LetterBoxCell.module.css';

const LetterBoxCell = (props) => {
    return (
        <div className = {styles.wholeCell}>
            <div className = {styles.box} id = {'box' + props.uniqueId}>
                <input type='text' className = {styles.inputField} 
                    id = {props.uniqueId} maxLength={1} disabled />
            </div>
        </div>
    )
}

export default LetterBoxCell;