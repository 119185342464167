import styles from "./GuessTheWordLevel.module.css";
import LevelThumbnail from "../Levels/LevelThumbnail";
import CrosswordThumbnail from './CrosswordThumbnail.png';
import $ from 'jquery';
import { useState } from "react";

const CrosswordLevels = () => {
    let [isLoading, setIsLoading] = useState(true);
    let [numberOfLevels, setNumberOfLevels] = useState(0);
    let levels = [];

    $.ajax( {
        type : 'POST',
        url : 'numberOfLevels.php',
        data : { 'type' : 'crossword' },
        success (data) {
            setNumberOfLevels(parseInt(data));
            setIsLoading(false);
        }
    })

    if(!isLoading) {
        for (let i = 0; i < numberOfLevels; i++) {
            levels.push(<LevelThumbnail puzzle = 'crossword' levelNumber = {i+1} clickHandler = {clickHandler} id = {(i+1).toString()} link = '/nav/crossword/' thumbnailImg = {CrosswordThumbnail} />)
        }
    }
    
    function clickHandler (event) {
        return event.target.id;
    }

    return (
        <div className = {styles.background}>
            <h1 className = {styles.title}>Technical Crossword</h1>
            <div className = {styles.wholeBody}>
                {levels}
            </div>
        </div>
        
    )
}

export default CrosswordLevels;