import styles from './GuessTheWordLevel.module.css';
import LevelThumbnail from '../Levels/LevelThumbnail';
import GuessTheWordThumbnail from './GuessTheWordThumbnail.png';
import { useState } from 'react';
import $ from 'jquery';

const GuessTheWordLevel = (props) => {
    let [isLoading, setIsLoading] = useState(true);
    let [numberOfLevels, setNumberOfLevels] = useState(0);
    let levels = [];
    
    $.ajax( {
        type : 'POST',
        url : 'numberOfLevels.php',
        data : { 'type' : 'guessTheWord' },
        success (data) {
            setNumberOfLevels(parseInt(data));
            setIsLoading(false);
        }
    })


    if (!isLoading) {
        for (let i = 0; i < numberOfLevels; i++) {
            levels.push(<LevelThumbnail puzzle = 'word' levelNumber = {i + 1} clickHandler = {clickHandler} id = {(i + 1).toString()} link = '/nav/guessTheWord/' thumbnailImg = {GuessTheWordThumbnail}/>)
        }
    }

    function clickHandler (event) {
        return event.target.id;
    } 

    if (isLoading) {
        return (
            <div>Loading---------------</div>
        )
    }

    return (
        <div className = {styles.background}>
            <h1 className = {styles.title}>Guess The Word</h1>
            <div className = {styles.wholeBody}>
                {levels}
            </div>
        </div>
        
    )
}

export default GuessTheWordLevel;