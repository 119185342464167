import Cell from './Cell.js';
import { useEffect, useState } from 'react';
import CellBlack from './Cell_Black.js';
import styles from './CellGrid.module.css';
import $ from 'jquery';

const CellGrid = (props) => {

    let [align, setAlign] = useState('H');
    let [curr, setCurr] = useState('');
    const x = props.crossWord.length;
    const y = props.crossWord[0].length;
    let counter = 1;
    let componentsArr = [];
    let crossWordArr = [];
    
    for (let i = 0; i < x; i++) {
        let tempArr1 = [];
        for (let j = 0; j <= y; j++) {
            tempArr1.push(props.crossWord[i][j]);
        }
        crossWordArr.push(tempArr1);
    }

    function getRowColumn (idString) {
        let row_column = [];
        let temp = '';
        for(let i = 0; i < idString.length; i++) {
            if(idString[i] == '.') {
                row_column.push(temp);
                temp = '';
            }
            else {
                temp += idString[i];
            }
        }
        row_column.push(temp);
        return row_column;
    }
    
    function checkCellNumber(i, j, arr) {
        if (arr[i][j] == " ") {
            return " ";
        }
        else {
            let result = [false, false];
            if (i == 0) {
                if (arr[i+1][j] != " ") {
                    result[1] = true;
                }
                if (j != arr[0].length - 1 && j != 0) {
                    if (arr[i][j+1] != " " && arr[i][j-1] == " ") {
                        result[0] = true;
                    }
                }
            }
            else {
                if (j != 0)
                {
                    if ((j < arr[0].length-1) && arr[i][j-1] == " " && arr[i][j+1] != " ") {
                        result[0] = true;
                    } 
                }
            }
            if (j == 0) {
                if (arr[i][j+1] != " ") {
                    result[0] = true;
                }
                if (i != arr.length - 1 && i != 0) {
                    if (arr[i+1][j] != " " && arr[i-1][j] == " ") {
                        result[1] = true;
                    }
                }
            }
            else {
                if (i != 0) {
                    if ((i < arr.length-1) && arr[i-1][j] == " " && arr[i+1][j] != " ") {
                        result[1] = true;
                    }
                }
                
            }
            if(result[0] || result[1])
                return counter++;

            return " ";
        }
    }
    
    for (let i = 0; i < x; i++) {
        let tempArr = [];
        for (let j = 0; j <= y; j++) {
            if(j == y)
            {
                //tempArr.push(<br></br>)
            }
            else {
                if(props.crossWord[i][j] == ' ')
                {
                    tempArr.push(<td className = {styles.cells}> <CellBlack uniqueId = {i.toString() + '.' + j.toString()} /> </td>);
                }
                else {
                    tempArr.push(<td className = {styles.cells}><Cell cellNumber = {checkCellNumber(i, j, props.crossWord).toString()} uniqueId = {i.toString() + '.' + j.toString()} changeHandler = {changeHandler} clickHandler = {clickHandler} clickAwayHandler = {clickAwayHandler} focusHandler = {focusHandler} beforeInputHandler = {beforeInputHandler} /> </td>);
                }
            }
        }
        componentsArr.push(<tr className = {styles.tr}>{tempArr}</tr>);
    }

    let finalTable = [];
    finalTable.push(<table className = {styles.tableStyle}>{componentsArr}</table>)

    function beforeInputHandler (event) {
        if(event.target.value != '')
            event.target.value = '';
    }

    useEffect(() => {
        const alignDisplay = () => {
            if(align == 'H')
                document.getElementById('alignDisplay').innerText = 'Across';
            else
                document.getElementById('alignDisplay').innerText = 'Down';
        }
        alignDisplay();
    }, [align])

    function focusHandler(event) {
        let ids = getRowColumn(event.target.id);
        document.getElementById(ids[0] + '.' + ids[1]).style.backgroundColor = "#d8c3a5";
        document.getElementById('o' + ids[0] + '.' + ids[1]).style.backgroundColor = "#d8c3a5";
        document.getElementById('i' + ids[0] + '.' + ids[1]).style.backgroundColor = "#d8c3a5";
        document.getElementById('no' + ids[0] + '.' + ids[1]).style.backgroundColor = "#d8c3a5";
    }

    function clickHandler (event) {
        if(event.target.id == curr)
        {
            if(align == 'H')
                setAlign('V');
            else
                setAlign('H');
        }
        else
        {
            setCurr(event.target.id);
        }
    }

    function clickAwayHandler (event) {
        let ids = getRowColumn(event.target.id);
        document.getElementById(ids[0] + '.' + ids[1]).style.backgroundColor = "#E8E9EB";
        document.getElementById('o' + ids[0] + '.' + ids[1]).style.backgroundColor = "#E8E9EB";
        document.getElementById('i' + ids[0] + '.' + ids[1]).style.backgroundColor = "#E8E9EB";
        document.getElementById('no' + ids[0] + '.' + ids[1]).style.backgroundColor = "#E8E9EB";
    }

    function changeHandler (event) {
        const currentCell = getRowColumn(event.target.id);
        if(props.crossWord[currentCell[0]][currentCell[1]].toUpperCase() != event.target.value.toUpperCase()) {
            if(event.target.style.color == "green") {
                event.target.style.color = "red";
            }
        }
        else {
            if (event.target.style.color == "red") {
                event.target.style.color = "green";
            }
        }

        if(align == 'H')
        {
            if(event.target.value == '')
            {
                const prevCell = document.getElementById(currentCell[0] + '.' + (parseInt(currentCell[1]) - 1).toString());
                if(prevCell)
                    prevCell.focus();
            }
            else {
               const nextCell = document.getElementById(currentCell[0] + '.' + (parseInt(currentCell[1]) + 1).toString());
                if(nextCell)
                    nextCell.focus();
            }           
        }

        if(align == 'V')
        {
            if(event.target.value == '')
            {
                const prevCell = document.getElementById((parseInt(currentCell[0]) - 1).toString() + '.' + currentCell[1]);
                if(prevCell)
                    prevCell.focus();
            }
            else {
                const nextCell = document.getElementById((parseInt(currentCell[0]) + 1).toString() + '.' + currentCell[1]);
                if(nextCell)
                    nextCell.focus();
            }  
        }
    }
    
    function setLevelComplete () {
        $.ajax({
            type : 'POST',
            url : 'setLevelComplete.php',
            data : {
                'username' : localStorage.getItem('token'),
                'levelname' : 'crossword' + props.levelNo,
            },
        })
    }

    function evaluate() {
        let isComplete = true;

        for(let i = 0; i < crossWordArr.length; i++) {
            for(let j = 0; j < crossWordArr[0].length-1; j++) {
                const currentCell = document.getElementById(i.toString() + '.' + j.toString());
                if(currentCell)
                {
                    const input = currentCell.value.toUpperCase();
                    if(input == '')
                    {
                        isComplete = false;
                        continue;
                    }
                    else if (input == crossWordArr[i][j])
                    {
                        document.getElementById(i.toString() + '.' + j.toString()).style.color = "green";
                    }
                    else
                    {
                        isComplete = false;
                        document.getElementById(i.toString() + '.' + j.toString()).style.color = "red";
                    }
                }
                else
                    continue;
            }
        }
        
        if(isComplete)
        {
            document.getElementById('won').style.display = 'flex';
            if(localStorage.getItem('token') != null)
            {
                setLevelComplete();
            }
        }
    }

    function revealCrossword() {
        for(let i = 0; i < crossWordArr.length; i++) {
            for(let j = 0; j < crossWordArr[0].length-1; j++) {
                const currentCell = document.getElementById(i.toString() + '.' + j.toString());
                if(currentCell) {
                    currentCell.value = crossWordArr[i][j];
                }
            }
        }
    }

    return (
        <div id = 'CellGrid' className = {styles.blockGrid}>
            <div className = {styles.menuBar}>
                <div id = 'alignDisplay' className = {styles.alignDisplay}>Initial</div>
                <button className = {styles.buttons} type = 'button' onClick={evaluate} id = 'popupButton'>Check Crossword</button>
                <button className = {styles.buttons} type = 'button' onClick={revealCrossword}>Reveal Crossword</button>
            </div>
            <div className={styles.gridBorder}>{finalTable}</div>                
        </div>
    )
}

export default CellGrid;