import styles from './Credits.module.css';

const Credits = () => {
    return (
        <div className = {styles.fullBody}>
            <center>
                <h1 className = {styles.title}>Credits</h1>
                <h2 className = {styles.content}>Creators:</h2>
                <h2 className = {styles.content}>Ashish Avhad</h2>
                <h2 className = {styles.content}>Tushar Pawar</h2>
                <h2 className = {styles.content}>Gaurav Aher</h2>
                <h2 className = {styles.content}>Sharvay Chandgude</h2>
                <a className = {styles.content} href='https://www.flaticon.com/' target='_blank'>Icons: flaticon.com</a>
            </center>
        </div>
    )
}

export default Credits;