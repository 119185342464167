import { useState } from 'react';
import styles from './Registration.module.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';

const Login = () => {
    let [loggedIn, setLoggedIn] = useState(false);
    let [result, setResult] = useState('');

    function submitHandler () {
        
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;
        console.log(username);

        if(username != '' && password != '') {
            $.ajax ({
                type : 'POST',
                url : 'userRegistration.php',
                data : {
                    'type' : 'login',
                    'username' : username,
                    'password' : password,
                },
                success(data) {
                    if(data == 'true') {
                        setLoggedIn(true);
                        localStorage.setItem('token', username);
                        window.location.reload();
                    }
                    else {
                        setResult('Invalid username or password');
                    }
                }
            })
        }
    }
    
    if(localStorage.getItem('token') != null) {
        return (
            <div className = {styles.wholebody}>
                <div className = {styles.box}>
                    <p3 className = {styles.heading} style = {{textAlign : 'center'}}>Signed in successfully</p3>
                </div>
            </div>
        )
    }

    return (
        <div className = {styles.wholebody}>
            <div className = {styles.box}>
                <p className = {styles.heading} >Username</p>
                <input type = 'text' className = {styles.input} id = 'username'/>
                <p className = {styles.heading}>Password</p>
                <input type = 'password' className = {styles.input} id = 'password'/>
                <button type = 'submit' className = {styles.button} onClick = {submitHandler}>Login</button>
                <p className = {styles.heading} style = {{textAlign : 'center'}}>{result}</p>
                <p className = {styles.text}>Don't have an account? signup <Link to = '/nav/signUp' className = {styles.link}>here</Link></p>
            </div>
        </div>
    )
}

export default Login;