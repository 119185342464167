import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import NavBar from './Modules/Pages/NavBar.js';
import CrossWord from './Modules/Pages/CrossWord.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Wordle from './Modules/Pages/Wordle.js';
import Home from './Modules/Pages/Home.js';
import Login from './Modules/Pages/Login.js';
import GuessTheWordLevel from './Modules/Pages/GuessTheWordLevel.js';
import AdminDashboard from './Modules/Pages/AdminDashboard';
import CrosswordEntry from './Modules/Pages/CrosswordEntry.js';
import CrosswordLevels from './Modules/Pages/CrosswordLevels';
import Registration from './Modules/Pages/Registration';
import Menu from './Modules/Pages/Menu';
import GuessWordEntry from './Modules/Pages/guessWordEntry.js';
import Credits from './Modules/Pages/Credits';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
        <Route index element = {<Home />} />
        <Route path="/nav/" element={<NavBar />}>
          <Route path="crossword/*" element = {<CrossWord />} />
          <Route path="guessTheWord/*" element = {<Wordle />} />
          <Route path="guessTheWordLevels" element = {<GuessTheWordLevel />} />
          <Route path="signIn" element = {<Login />} />
          <Route path="dashboard" element = {<AdminDashboard />} />
          <Route path="crosswordEntry" element = {<CrosswordEntry />} />
          <Route path="crosswordLevels" element = {<CrosswordLevels />} />
          <Route path="signUp" element = {<Registration />} />
          <Route path="menu" element = {<Menu />} />
          <Route path="guessWordEntry" element = {<GuessWordEntry />} />
          <Route path="credits" element = {<Credits />} />
        </Route>
      </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
