import LetterBoxGrid from '../GuessWord/LetterBoxGrid.js';
import styles from './Wordle.module.css';
import HintIcon from './HintIcon.png';
import $ from 'jquery';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Wordle = (props) => {
    let [isloading, setIsLoading] = useState(false);

    let [word, setWord] = useState('');
    let [hint, setHint] = useState('');
    const index = useLocation()['pathname'].lastIndexOf('/');
    const id = useLocation()['pathname'].substring(index + 1);

    $.ajax ({
        type : 'POST',
        url : 'guessTheWord.php',
        data : { 'id' : id.toString() },
        success(data) {
            const arr = JSON.parse(data);
            setWord(arr[0]);
            setHint(arr[1]);
            setIsLoading(false);
        }
    });

    function reload () {
        window.location.reload(false);
    }

    if (isloading)
    {
        return (
            <div>
                Loading------------
            </div>
        )
    }

    function close() {
        document.getElementById('HowToPlayContent').style.display = 'none';
    }

    function open() {
        document.getElementById('HowToPlayContent').style.display = 'block';
    }

    return (
        <div className = {styles.wholeBody}>
            <LetterBoxGrid word = {word} levelNo = {id}/>
            <div className = {styles.howToPlay} onClick = {open}>
                How to play?
            </div>
            <div className = {styles.howToPlayContent} id = 'HowToPlayContent'>
                <p className = {styles.x}>
                    <div onClick={close} className = {styles.x1}>X</div>
                </p>
                <p>Guess the 'Technical' word in given number of guesses to win</p>
                <p>The word can be any technical term - programming languages, computer softwares, or hardware terms</p>
                <p>To get some clue about the word use 'Hint'</p>
                <p>After each guess colors change to show accuracy of your guess</p>
                <div style = {{display: 'flex', alignItems: 'center'}}>
                    <div className = {styles.colorBlock} style = {{backgroundColor: '#14a76c'}}/>
                    <p className = {styles.text} style = {{marginLeft: '20px'}}>Letter is at the right position</p>
                </div>
                <div style = {{display: 'flex', alignItems: 'center'}}>
                    <div className = {styles.colorBlock} style = {{backgroundColor: '#d79922'}}/>
                    <p className = {styles.text} style = {{marginLeft: '20px'}}>Letter is in the word but at the wrong position</p>
                </div>
                <div style = {{display: 'flex', alignItems: 'center'}}>
                    <div className = {styles.colorBlock} style = {{backgroundColor: '#747474'}}/>
                    <p className = {styles.text} style = {{marginLeft: '20px'}}>Letter is not present in the required word</p>
                </div>
            </div>
            <div className = {styles.hint}>
                <img src = {HintIcon} style = {{height: '2.5vh'}}/>Hint
                <div className = {styles.hintText}>{hint}</div>
            </div>
            <div>
                
            </div>
            <div className = {styles.result} id = 'won'>
                Correct Guess!
                <Link to = '/nav/guessTheWordLevels' className = {styles.h3}>All Levels</Link>
            </div>
            <div className = {styles.result} id = 'lost'>
                Better luck next time!
                <h3 className = {styles.h3} onClick = {reload}>Try Again</h3>
                <Link to = '/nav/guessTheWordLevels' className = {styles.h3}>All Levels</Link>
            </div>
        </div>
    )
}

export default Wordle;